<template>
    <el-dialog :title="title" width="40%" :close-on-click-modal="false" append-to-body :visible.sync="visible">
        <el-form size="small" :model="inputForm" ref="inputForm" v-loading="loading"
                 label-width="120px">
            <el-form-item prop="parentId" label="上级：" :rules="[
                        {required: inputForm.departmentType == 1 ? true : false, message: '上级不能为空', trigger: 'blur'},
                    ]">
                <SelectTree
                        ref="menuParentTree"
                        :props="{
                value: 'id',             // ID字段名
                label: 'departmentName',         // 显示名称
                children: 'children'    // 子级字段名
              }"
                        :data="list"
                        :value="inputForm.parentId"
                        :clearable="method == 'add'"
                        :accordion="true"
                        :disabled="inputForm.departmentType == 1 ? false : true && method == 'edit'"
                        @getValue="(value) => {inputForm.parentId=value}"/>
            </el-form-item>
            <el-form-item label="机构/部门：" prop="departmentName" :rules="[
                        {required: true, message: '机构/部门不能为空', trigger: 'blur'},
                    ]">
                <el-input v-model.trim="inputForm.departmentName" maxlength="50" placeholder="请输入机构/部门（限50字）"
                          clearable></el-input>
            </el-form-item>
            <el-form-item label="启用状态：" prop="enabled">
                <el-switch v-model="inputForm.enabled"
                           active-value="0" inactive-value="1"
                ></el-switch>
            </el-form-item>
        </el-form>
        <div slot="footer" class="text_center">
            <el-button size="small" @click="visible = false">取消</el-button>
            <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import SelectTree from '@/components/treeSelect/treeSelect.vue'

    export default {
        components: {
            SelectTree,
        },
        data() {
            return {
                title: '',
                visible: false,
                method: '',
                loading: false,
                inputForm: {
                    id: '',
                    departmentName: '',
                    departmentType: '',
                    parentId: '',
                    enabled: '0',
                },
                list: [],
                num: 1
            }
        },
        methods: {
            init(row, method) {
                this.getSysLibrary(row)
                this.loading = false
                this.method = method
                this.inputForm.departmentType = ''
                this.inputForm.parentId = ''
                if (row) {
                    this.inputForm.id = row.id
                } else {
                    this.inputForm.id = ''
                }
                if (method === 'add') {
                    this.title = '新增组织机构'
                } else if (method === 'edit') {
                    this.title = '修改组织机构'
                } else if (method === 'view') {
                    this.title = '查看组织机构'
                }
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                })
            },
            // 获取上级机构
            getSysLibrary(row) {
                this.loading = true
                this.$axios(this.api.user.getSysDepartment, {}, 'post').then(res => {
                    if (res.status) {
                        this.list = res.data
                        this.$nextTick(() => {
                            if (this.method != 'add') {
                                this.inputForm = this.recover(this.inputForm, row)
                                this.setDisabledTreeData(this.list, this.inputForm.id)
                            }
                            this.getLevel(this.list)
                        })
                    }
                    this.loading = false
                })
            },
            // 设置禁选（不能选择当前模板名称）
            setDisabledTreeData(data, id) {
                data.map((item) => {
                    if (item.id == id) {
                        this.$set(item, "disabled", true);
                    }
                    let isFatherNode = item.children && item.children.length > 0;
                    isFatherNode && this.setDisabledTreeData(item.children, id);
                });
            },
            // 获取层级
            getLevel(list) {
                list.map((item) => {
                    this.$set(item, "level", this.num);
                    if(item.children.length) {
                        this.num++
                        this.getLevel(item.children)
                    } else {
                        this.num = 1
                    }
                    this.$nextTick(() => {
                        if (item.level >= 10) {
                            this.$set(item, "disabled", true);
                        }
                    })
                })
            },
            // 提交
            doSubmit() {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        let api = this.api.user.departmentSave,
                            type = 'post'
                        if (this.method == 'edit') {
                            api = this.api.user.departmentUpdateById
                            type = 'put'
                        }
                        this.$axios(api, this.inputForm, type).then(data => {
                            this.loading = false
                            if (data && data.status) {
                                this.$message.success(data.msg)
                                this.visible = false
                                this.$emit('refreshDataList')
                            } else {
                                this.$message.error(data.msg)
                            }
                        })
                    }
                })
            },
        },
    }
</script>

<style scoped>

</style>
